import React, { useState } from 'react';
import './App.css';

function App() {
  const [inputText, setInputText] = useState('');
  const [outputText, setOutputText] = useState('');

  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  const handleSubmit = async () => {
    // Placeholder for Bedrock API call
    const response = await callBedrock(inputText);
    setOutputText(response);
  };

  const callBedrock = async (input) => {
    // TODO: Implement actual Bedrock API call
    return `Bedrock response for: ${input}`;
  };

  return (
    <div className="App">
      <nav className="side-panel">
        <h2>Navigation</h2>
        <ul>
          <li><a href="#home">Home</a></li>
          <li><a href="#about">About</a></li>
          <li><a href="#services">Services</a></li>
          <li><a href="#contact">Contact</a></li>
        </ul>
      </nav>
      <main className="main-content">
        <h1>Ask Questions of my resume!</h1>
        <div className="input-container">
          <input
            type="text"
            value={inputText}
            onChange={handleInputChange}
            placeholder="Enter your text here"
          />
          <button onClick={handleSubmit}>Submit</button>
        </div>
        <div className="output-container">
          <textarea
            readOnly
            value={outputText}
            placeholder="Output will appear here"
          />
        </div>
      </main>
    </div>
  );
}

export default App;
